$body-bg: 				#f9f9f9;
$body-color: 			#272727;
$highlight-color:		#D8AB5B;

$content-bg:			#FFFFFF;
$content-border-color:  #DADADA;

$menu-bg: 				#4E483D;
$menu-color: 			#FFFFFF;
$menu-link-color: 		#D8AB5B;
$menu-link-border:		#61553D;
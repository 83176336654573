@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');
@import '_vars.scss';

html, body {
	font-family: 'Open Sans', sans-serif;
	-webkit-tap-highlight-color: transparent;
}
/* Application loading screen */
.app-loading {
	position: fixed;
	width: 100%;
	height: 100%;

	.app-header-logo {
		position: absolute;
		left: 50%;
		margin-left: -65px;
		bottom: 4em;
		top: auto;
	}
}
.app-loader {
	height: 140px;
	position: absolute;
	top: 50%;
	margin-top: -70px;
	width: 100%;
	text-align: center;
	background-color: $body-bg;


}

.isvrs-logo {
	background-image: url('../images/isvrs-logo-light.svg');
	background-position: center;
	background-repeat: no-repeat;
	width: 217px;
	height: 50px;
	position: absolute;
	left: 50%;
	top: 3em;
	margin-left: -115px;
}
.app-article {
	font-weight: 300;
	.isvrs-logo {
		position: static;
		width: 100%;
		margin-left: 0;
		margin-top: 40px;
		margin-bottom: 20px;
		background-position: left;
	}
}

/* Application city select */
.app-city-select {
	.app-content {
		padding-top: 1.5em;
	}
	.isvrs-logo {
		position: static;
		width: 100%;
		margin-left: 0;
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.app-header-logo {
		position: static;
		margin-left: 0;
		margin-bottom: 3em;
		margin-top: 1em;
		width: 100%;
	}
	p {
		text-align: center;
		font-size: 1.4em;
		margin-bottom: 1em;
		font-weight: 300;
	}
	small {
		font-size: 0.7em;
		font-weight: 300;
	}
	.cities-select {
		padding-top: 7px;
		padding-bottom: 7px;
		margin: 30px 20px 0px 20px;
	}
	.app-city-item {
		cursor: pointer;
		padding: 12px 10px;
		font-size: 1.2em;
		color: $highlight-color;
		font-weight: 300;
		border-bottom: 1px solid rgba(40, 40, 40, 0.1);
		   
		&:last-child {
			border-bottom: none;
		}
	}
}
.app-back {
	.app-header-logo { 
		left: 65px;
	}
}
.app-back-button {
	cursor: pointer;
	position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-image: url('../images/back-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}

/* Application header */
.app-header {
	position: fixed;
	height: 65px;
	width: 100%;
	background-color: $body-bg;
	z-index: 1000;

	&:after {
		content: " ";
		display: block;
		position: absolute;
		height: 20px;
		width: 100%;
		top: 65px;

		background-color: rgba(255,255,255,0);
		background-image: -webkit-gradient(linear, left top, left bottom, from($body-bg), to(rgba(255,255,255,0)));
		background-image: -webkit-linear-gradient(top, $body-bg, rgba(255,255,255,0));
		background-image:    -moz-linear-gradient(top, $body-bg, rgba(255,255,255,0));
		background-image:      -o-linear-gradient(top, $body-bg, rgba(255,255,255,0));
		background-image:         linear-gradient(to bottom, $body-bg, tranrgba(255,255,255,0));
	}
}

.app-scroll-to-top {
	position: absolute;
	top: 5px;
	right: 60px;
	height: 60px;
	width: 60px;
	background-image: url('../images/arrow-up-icon.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px 20px;
	transition: opacity 0.3s;
	-webkit-transition: opacity 0.3s;
	opacity: 0;

	&.show {
		opacity: 1;
	}
}

/* Application logo */
.app-header-logo,
.app-menu-logo {
	background-image: url('../images/logo.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 130px;
	height: 30px;
	position: relative;
	top: 20px;
	left: 5px;
}
.app-menu-logo {
	position: static;
	background-image: url('../images/logo-dark.svg');
	margin-bottom: 1.5em;
}

/* Application menu */
.app-header-content {
	height: 0px;
}
.bm-burger-button {
	position: absolute;
	width: 60px;
	height: 60px;
	right: 0px;
	top: 5px;
	background-image: url('../images/menu-icon.svg');
	background-repeat: no-repeat;
	background-position: center; 
}
.bm-burger-bars {
  display: none;
}
.bm-cross-button {
	background: none;
	background-image: url('../images/close-icon.svg');
	background-position: center;
	background-repeat: no-repeat;
	width: 50px !important;
	height: 50px !important;
}
.bm-menu {
  background: $menu-bg;
  padding: 2em 2.2em 0;
  font-size: 1.3em;
  /*box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.4);*/
}
.bm-overlay {
  background: rgba(248, 248, 248, 0.7) !important;
}
.menu-item {
	cursor: pointer;
	background: none;
    border: none;
	display: block;
	color: $menu-link-color;
	font-weight: 300;
	font-size: 1.25rem;
	border-bottom: 1px solid $menu-link-border;
	padding: 14px 0;
	width: 100%;
    text-align: left;

	&:last-child {
		border-bottom: none;
	};

	&:active {
		color: $menu-link-color;
		text-decoration: none;
	}

	&:visited {
		color: $menu-link-color;
		text-decoration: none;
	}
}
.isvrs-menu-logo {
	background-image: url('../images/isvrs-logo.svg');
	background-position: center;
	background-repeat: no-repeat;
	width: 184px;
	height: 38px;
	position: absolute;
	bottom: 2.5em;
}


/* Content styling */
.app-content {
	padding: 80px 5px 20px;
}

.main-date {
	font-size: 1.2rem;
	font-weight: 300;
	text-align: right;
}
.main-alt-date {
	font-weight: 300;
	text-align: right;
}

.feed-section {
	font-weight: 300;
	padding: 10px 5px;

	&:not(.first) {
		margin-top: 15px;
	}
}
.feed-section-title {
	float: left;
}
.feed-section-timer {
	float: right;
}

.feed-box {
	background-color: $content-bg;
	border: 1px solid $content-border-color;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
	padding: 12px 20px;
	margin-bottom: 5px;

	&.active {
		padding: 15px 20px;	
	}
}

.prayer {
	&.active {
		font-size: 1.1rem;

		.prayer-time {
			padding-left: 30px;
			&:before {
				width: 20px;
				height: 20px;
			}
		}
	}
}
.prayer-name {
	width: 60%;
	float: left;

	span {
		margin-left: 10px;
		font-weight: 300;
		display: inline-block;
	}
}
.prayer-time {
	float: right;
	text-align: right;
	padding-left: 30px;
	position: relative;

	&:before {
		content: " ";
		background-image: url('../images/time-icon.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		width: 18px;
		height: 18px;
		left: 0;
		top: 4px;
	}
}

/* calendar feed */
.calendar-day {
	margin-bottom: 10px;
}

.calendar-day-header {
	line-height: 1.2em;
	margin-top: 10px;
	margin-bottom: 20px;

	.day {
		color: $highlight-color;
		font-size: 1.2rem;
		margin-right: 0.5em;
		float: left;
	}
	.name {
		margin-right: 0.5em;
		float: left;
	}
	.today {
		font-weight: 300;
		float: left;
	}
	.hijra-date {
		float: right;
		font-weight: 300;
		font-size: 0.9rem;
	}
}

.calendar-day-prayers {
		margin-left: -10px;
		margin-right: -10px;
		margin-bottom: 10px;
	.item {
		float: left;
		width: (100% / 6);
		text-align: center;
		border-right: 1px solid rgba(0,0,0,0.05);
		&:last-child {
			border-right: none;
		}
	}

	.name {
		font-size: 0.8rem;
		font-weight: 300;
		margin-bottom: 2px;
	}
	.time {
		font-size: 0.9rem;
	}
}

.calendar-tip {
	margin-bottom: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	
	margin-top: -5px;
	.title {
		
	}
	.content {
		margin-top: 10px;
		font-weight: 300;
		font-size: 0.9rem;
	}
}

.print-icon {
	float: right;
	color: $highlight-color;
	cursor: pointer;
	position: relative;

	&:before {
		content: " ";
		background-image: url('../images/print-icon.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		width: 18px;
		height: 18px;
		left: -25px;
		top: 3px;
		opacity: 0.7;
	}
}

.ramazan-box {

	padding-top: 20px;
	padding-bottom: 15px;
	margin-top: 20px;
	margin-bottom: 15px;

	.title {
		font-size: 18px;
		margin-bottom: 10px;

	}

	.name-and-time {
		font-size: 18px;
		font-weight: 800;
		text-align: left;

		span {
			float: right;
			font-weight: 300;
			font-size: 14px;
			line-height: 26px;
		}
	}

	.dua-title {
		font-weight: 300;
    	padding: 10px 0px 5px 0px;
	}

	.dua-org {
    	padding: 10px 0px 5px 0px;
	}

	.dua-trans {
		font-weight: 300;
    	padding: 10px 0px;
    	font-size: 14px;
	}
}

